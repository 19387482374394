/* eslint-disable react/jsx-pascal-case */
import { useFetchedCompanies } from "./useFetchedCompanies";
import { useMemo, useState } from "react";
import {
  MaterialReactTable,
  MRT_ColumnDef,
  useMaterialReactTable,
} from "material-react-table";
import { PanelSpinner } from "../common-ui/Spinner";
import { Company } from "./types";
import { Box, Button } from "@mui/material";
import { Add } from "@mui/icons-material";
import { CompanyDialog } from "./CompanyDialog";

export const Companies = () => {
  const { data: companies } = useFetchedCompanies();
  const columns = useMemo<MRT_ColumnDef<Company>[]>(
    () => [
      {
        header: "Name",
        accessorKey: "name",
        enableHiding: false,
      },
      {
        header: "VAT Number",
        accessorKey: "vatNumber",
      },
    ],
    [],
  );

  const companiesOrEmpty = companies ?? [];

  const [newCompany, setNewCompany] = useState<Company | null>(null);

  const table = useMaterialReactTable({
    columns,
    data: companiesOrEmpty,
    enableGlobalFilter: false,
    enablePagination: false,
    editDisplayMode: "modal",
    enableEditing: true,
    renderTopToolbarCustomActions: () => (
      <Button
        startIcon={<Add />}
        onClick={() =>
          setNewCompany({
            id: "",
            name: "",
            vatNumber: null,
            vatNumberVerified: null,
            aliases: [],
            rootFolderId: "",
          })
        }
      >
        New company
      </Button>
    ),
    renderEditRowDialogContent: ({ row, table }) => {
      return (
        <CompanyDialog
          company={row.original}
          open={true}
          onClose={() => {
            table.setEditingRow(null);
          }}
        />
      );
    },
  });

  if (!companies) return <PanelSpinner />;
  return (
    <>
      <Box>
        <MaterialReactTable table={table} />
      </Box>
      {newCompany && (
        <CompanyDialog
          company={newCompany}
          open={true}
          onClose={() => setNewCompany(null)}
        />
      )}
    </>
  );
};
